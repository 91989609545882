// i18next-extract-mark-ns-start tpv-virtual
import React from 'react';
import styled, {css} from 'styled-components';

import {PageProps, graphql, navigate} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';

import payment_gateway from 'images/payment_gateway.svg';
import custom_checkout_page_en from 'images/custom_checkout_page_en.png';
import custom_checkout_page_es from 'images/custom_checkout_page_es.png';
import pay_by_link_EN from 'images/pay-by-link-feature.svg';
import pay_by_link_ES from 'images/pay-by-link-feature_es.svg';
import omnichannel from 'images/omnichannel.svg';
import clock from 'images/clock.svg';
import shield from 'images/shield.svg';
import coinstack from 'images/coinstack.svg';
import trendgraph from 'images/trendgraph.svg';
import person from 'images/person.svg';
import payment_page_EN from 'images/payment-page-preview.png';
import payment_page_ES from 'images/payment-page-preview_es.png';
import recurring_payments from 'images/recurring_payments.png';
import digital_payments from 'images/digital payments.png';
import cart2 from 'images/cart2.svg';

import {AnchorLink} from 'components/AnchorLink';
import {Button} from 'components/Button';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {Content} from 'components/Content';
import {Clients, defaultClientList} from 'components/Clients';
import CardsContainer from 'components/CardsContainer';
import {Card} from 'components/Card';
import {Section, SectionActions, SectionHeader} from 'components/Section';
import {SEO} from 'components/SEO';
import {ScreenSizes, Sx} from '../../types/responsive';
import {SignUpButton} from 'components/SignUpButton';
import {InternalPageLink} from 'components/links/Pages';
import {SupportLink} from 'components/links/Support';
import {StyledDiv, StyledImg, StyledP, StyledSection} from 'components/StyledSx';
import {HomePartners} from 'components/HomePartners';
import {GoogleReviews, Place} from 'components/GoogleReviews';
import {
  GradientBox,
  GradientCtaImage,
  GradientSectionDescription
} from 'components/landings/GradientBox';
import {FaqsSection} from 'components/FaqsSection';
import CodeSnippets from 'components/CodeSnippets';
import {Slider} from 'components/Slider';
import {BlogLink} from 'components/links/Blog';

import visa_logo from '@wp-pay/logos/dist/methods/visa/method-visa-640x360.svg';
import paypal_logo from '@wp-pay/logos/dist/methods/paypal/method-paypal-640x360.svg';
import mastercard_logo from '@wp-pay/logos/dist/methods/mastercard/method-mastercard-640x360.svg';
import googlepay_logo from '@wp-pay/logos/dist/methods/google-pay/method-google-pay-640x360.svg';
import applepay_logo from '@wp-pay/logos/dist/methods/apple-pay/method-apple-pay-640x360.svg';
import bizum_logo from '../images/bizum_payment_gateway.svg';
import mb_way_logo from '@wp-pay/logos/dist/methods/mb-way/method-mb-way-640x360.svg';
import sepa_logo from 'images/sepa-logo.svg';
import jcb_logo from '@wp-pay/logos/dist/cards/jcb/card-jcb-logo-_x80.svg';
import dinners_logo from '@wp-pay/logos/dist/cards/diners-club/card-diners-club-logo-_x80.svg';
import discover_logo from '@wp-pay/logos/dist/cards/discover/card-discover-logo-_x80.svg';

type SectionProps = {
  textAlign?: 'center' | 'left' | 'right';
  centered?: boolean;
  reverseOnMobile?: boolean;
  column?: boolean;
  columnMd?: boolean;
  columnLg?: boolean;
  sx?: Sx;
};

export const Background = styled(StyledDiv)`
  position: relative;
  padding-top: 100px;
  ${Section} {
    color: #ffffff;
    a {
      color: #ffffff;
    }
    a:hover {
      color: #efefef;
    }
  }

  &:before {
    display: block;
    content: '';
    position: absolute;
    transform: skew(0, -15deg);
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-image: linear-gradient(40deg, #12e0c2 0%, #aab4e9 40%);
  }
  &:after {
    display: block;
    position: absolute;
    content: '';
    z-index: -1;
    right: 0;
    left: 0;
    bottom: 0;
    height: 80%;
    background-image: linear-gradient(30deg, #12e0c2 0%, #aab4e9 40%);
  }
`;

const IndexBackground = styled.div`
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: -23%;
    height: 1256px;
    width: 1203px;
    background: linear-gradient(353.74deg, #41efd6 -47.57%, #aab4e9 13.85%);
    transform: matrix(2, 0, 0, -1, 1248, 4) rotate(47deg) skew(7deg, 1.6deg);
    @media (max-width: 1024px) {
      top: -32%;
      transform: matrix(2, 0, 0, -1, 1185, 25) rotate(44.5deg) skew(7deg, 1.6deg);
    }
    @media (max-width: 768px) {
      content: none;
    }
  }

  &:after {
    display: block;
    content: '';
    position: absolute;
    transform: rotate(34deg) matrix(1, 0, 0, -1, 459, -315);
    left: 50%;
    top: -6%;
    opacity: 1;
    height: 972px;
    width: 1289px;
    background: linear-gradient(353.74deg, #41efd6 -41.57%, #aab4e9 53.85%);
    @media (max-width: 1024px) {
      top: -35%;
      transform: rotate(34deg) matrix(1, 0, 0, -1, 470, -240);
    }
    @media (max-width: 768px) {
      content: none;
    }
  }
`;

const IndexImage = styled.img`
  position: absolute;
  left: 56%;
  top: 20px;
  width: 540px;
  z-index: 9;

  @media (max-width: 1024px) {
    left: 60%;
    width: 45%;
    top: 1%;
  }
`;

const IndexContent = styled.div`
  padding-top: 100px;
  padding-bottom: 0;
  max-width: 550px;
  min-height: 600px;
  @media (max-width: ${ScreenSizes.md}) {
    max-width: 500px;
    min-height: 510px;
  }
  @media (max-width: ${ScreenSizes.sm}) {
    max-width: 100%;
    min-height: 410px;
  }
`;

const CardWrapper = styled.div`
  max-width: 230px;
`;

const CardImage = styled.img`
  position: absolute;
`;

const FirstSectionHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h2 {
    margin-bottom: 24px;
  }
`;

const ReviewsBackground = styled.div`
  position: relative;

  &:before {
    display: block;
    content: '';
    z-index: -1;
    height: 70%;
    position: absolute;
    transform: skew(0, -9deg);
    left: 0;
    right: 0;
    bottom: 0;
    top: 20%;
    background: linear-gradient(96.34deg, #00e9d5 0%, #aab4e9 100%);
  }
`;

export const CustomSection = styled(StyledSection)<SectionProps>`
  position: relative;
  display: flex;
  flex-direction: row;
  padding-bottom: 100px;
  text-align: ${(props) => props.textAlign || 'left'};
  margin: 0 -30px;

  & > * {
    flex: 1;
    margin: 0 30px;

    @media (max-width: ${ScreenSizes.lg}) {
      flex: 3;
    }
    @media (max-width: ${ScreenSizes.md}) {
      margin: 0;
    }
  }

  a {
    text-decoration: underline;
  }

  ${(props) =>
    props.centered &&
    css`
      justify-content: center;
      align-items: center;
    `};
  ${(props) =>
    props.column &&
    css`
      flex-direction: column;
    `};

  @media (max-width: ${ScreenSizes.xl}) {
    padding-bottom: 15%;
  }
  @media (max-width: ${ScreenSizes.lg}) {
    ${(props) =>
      props.columnLg &&
      css`
        width: 100%;
        align-items: center;
        flex-direction: ${props.reverseOnMobile ? 'column-reverse' : 'column'};
      `};
  }
  @media (max-width: ${ScreenSizes.md}) {
    padding-bottom: 10%;
    ${(props) =>
      props.columnMd &&
      css`
        width: 100%;
        align-items: center;
        flex-direction: ${props.reverseOnMobile ? 'column-reverse' : 'column'};
      `};
  }
  @media (max-width: ${ScreenSizes.sm}) {
    padding-bottom: 80px;
    flex-direction: ${(props) => (props.reverseOnMobile ? 'column-reverse' : 'column')};
  }
  @media (max-width: ${ScreenSizes.md}) {
    margin: 0;
  }
`;

const TpvVirtual: React.FC<PageProps> = (props) => {
  const {t, language} = useI18next();
  const customCheckout = language === 'es' ? custom_checkout_page_es : custom_checkout_page_en;
  const paymentPagePreview = language === 'es' ? payment_page_ES : payment_page_EN;
  const payByLink = language === 'es' ? pay_by_link_ES : pay_by_link_EN;

  const paymentMethods: JSX.Element[] = [
    <StyledImg src={visa_logo} alt="Visa" title="Visa" />,
    <StyledImg src={paypal_logo} alt="PayPal" title="PayPal" />,
    <StyledImg src={mastercard_logo} alt="Mastercard" title="Mastercard" />,
    <StyledImg src={googlepay_logo} alt="Google Pay" title="Google Pay" />,
    <StyledImg src={applepay_logo} alt="Apple Pay" title="Apple Pay" />,
    <StyledImg src={bizum_logo} alt="Bizum" title="Bizum" />,
    <StyledImg src={mb_way_logo} alt="MB Way" title="MB Way" />,
    <StyledImg src={sepa_logo} alt="SEPA" title="SEPA" sx={{width: '80%'}} />,
    <StyledImg src={jcb_logo} alt="JCB" title="JCB" />,
    <StyledImg
      src={dinners_logo}
      alt="Dinners Club"
      title="Dinners Club"
      sx={{paddingRight: '20px'}}
    />,
    <StyledImg
      src={discover_logo}
      alt="Discover payment gateway"
      title="Discover payment gateway"
      sx={{paddingLeft: '20px'}}
    />
  ];

  const {data}: any = props;

  const place: Place = {
    rating: data.googlePlacesPlace.rating,
    user_ratings_total: data.googlePlacesPlace.user_ratings_total,
    reviews: data.allGooglePlacesReview.nodes
  };

  const faqs = [
    {
      header: t('How can I add a virtual POS to my website?'),
      text: t(
        'In order to accept payments online, you need a payment gateway. And depending on the payment gateway, it either comes with virtual POS services (like MONEI), or you need to get credentials from an acquiring bank.'
      ),
      content: (
        <Trans>
          In order to{' '}
          <BlogLink slug="how-to-accept-payments-online">accept payments online</BlogLink>, you need
          a payment gateway. And depending on the payment gateway, it either{' '}
          <InternalPageLink slug="features">comes with virtual POS services</InternalPageLink> (like
          MONEI), or you need to get credentials from an acquiring bank.
        </Trans>
      )
    },
    {
      header: t('What is the best virtual POS?'),
      text: t(
        'The ideal solution offers a range of features to help you accept payments online. That’s why opting for a payment gateway (like MONEI) that has built-in virtual POS or merchant acquiring services is the best option.'
      ),
      content: (
        <Trans>
          The ideal solution offers a range of{' '}
          <InternalPageLink slug="features">features</InternalPageLink> to help you accept payments
          online. That’s why opting for a{' '}
          <InternalPageLink slug="payment-gateway">payment gateway</InternalPageLink> (like MONEI)
          that has built-in virtual POS or merchant acquiring services is the best option.
        </Trans>
      )
    },
    {
      header: t('How can I accept online payments without a virtual POS?'),
      text: t(
        'You can’t. To accept online payments, you need a virtual POS or a payment gateway that has merchant acquiring services.'
      ),
      content: (
        <Trans>
          You can’t. In order to accept{' '}
          <InternalPageLink slug="online-payments">online payments</InternalPageLink>, you need a
          virtual POS or a payment gateway that has merchant acquiring services.
        </Trans>
      )
    },
    {
      header: (
        <Trans parent="div">
          What's the{' '}
          <BlogLink slug="payment-gateway-vs-virtual-pos">
            difference between a payment gateway and a virtual POS
          </BlogLink>
          ?
        </Trans>
      ),
      text: t(
        'When a customer initiates a payment, the virtual POS collects the information. The payment gateway connects to the virtual POS and routes the transaction to and from the payment processor for verification. Both are required in digital payments.'
      ),
      content: (
        <Trans>
          When a customer initiates a payment, the virtual POS collects the information. The payment
          gateway connects to the virtual POS and routes the transaction to and from the payment
          processor for verification. Both are required in digital payments.
        </Trans>
      )
    },
    {
      header: t('How much does a virtual POS cost?'),
      text: t(
        'It depends on the provider. With MONEI, you pay per transaction and your fees decrease as your sales volume increases.'
      ),
      content: (
        <Trans>
          It depends on the provider. With MONEI, you pay per transaction and your fees decrease as
          your sales volume increases.
        </Trans>
      )
    }
  ];

  const paymentCurl = `
  curl --request POST 'https://api.monei.com/v1/payments'
  --header 'Authorization: <API_KEY>'
  --header 'Content-Type: application/json'
  --data-raw '{
    "amount": 110,
    "currency": "EUR",
    "orderId": "14379133960355",
    "description": "Test Shop - #14379133960355",
    "customer": {
      "email": "john.doe@microapps.com"
    },
    "callbackUrl": "https://example.com/checkout/callback",
    "completeUrl": "https://example.com/checkout/complete",
    "cancelUrl": "https://example.com/checkout/cancel"
}'
`;

  const snippets = [{code: paymentCurl, language: 'javascript'}];

  const clientsList = defaultClientList.slice(0, 5).map((client) => {
    return {
      ...client,
      width: 180 * client.scale
    };
  });

  return (
    <>
      <SEO
        path="tpv-virtual"
        title={t('Virtual POS')}
        description={t(
          'You need a virtual POS to process online payments. Choose a payments platform with built in virtual POS services. Sign up for MONEI.'
        )}
      />
      <IndexBackground>
        <Content>
          <IndexContent>
            <SectionHeader underline tagName="h1">
              <Trans>Virtual POS: sell online, around the world</Trans>
            </SectionHeader>
            <Trans parent="p">
              Create fast, secure, and frictionless online payment experiences for your customers
              with MONEI’s virtual POS services.
            </Trans>
            <SectionActions>
              <SignUpButton variant="light" style={{marginRight: 15}}>
                <Trans>Open an Account</Trans>
              </SignUpButton>
              <ContactSalesButton />
            </SectionActions>
            <StyledP
              sx={{color: '#74858E', marginTop: '16px', fontSize: '14px', fontStyle: 'italic'}}>
              <Trans>
                *Currently supporting merchants in Spain and{' '}
                <InternalPageLink slug="andorra-payment-gateway">Andorra</InternalPageLink>
              </Trans>
            </StyledP>
          </IndexContent>
        </Content>
        <IndexImage
          src={payment_gateway}
          alt="MONEI payment gateway"
          title="MONEI payment gateway"
          className="hide-on-mobile"
        />
        <Content>
          <Clients clientList={clientsList} />
        </Content>
      </IndexBackground>

      <Content>
        <Section style={{alignItems: 'center'}}>
          <StyledDiv
            sx={{
              marginBottom: '0',
              display: {all: 'flex', sm: 'none'},
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <StyledImg
              src={digital_payments}
              alt="Digital payments"
              title="Digital payments"
              sx={{width: '55%'}}
            />
          </StyledDiv>
          <StyledDiv>
            <SectionHeader underline tagName="h2">
              <Trans>What is a virtual POS?</Trans>
            </SectionHeader>
            <Trans parent="p">
              A virtual POS is an{' '}
              <InternalPageLink slug="online-payments">online payment</InternalPageLink> terminal
              required to accept{' '}
              <BlogLink slug="accepting-credit-card-payments-without-merchant-account">
                credit card
              </BlogLink>{' '}
              and digital wallet payments through your e-commerce website. It’s a virtual point of
              sale for card-not-present transactions and is sometimes referred to as a merchant ID,
              merchant code, or merchant acquiring.
            </Trans>
          </StyledDiv>
        </Section>
      </Content>

      <Content>
        <Section style={{alignItems: 'center'}}>
          <StyledDiv>
            <SectionHeader underline tagName="h2">
              <Trans>How to get a virtual POS?</Trans>
            </SectionHeader>
            <Trans parent="p">
              First, you need to go to your{' '}
              <BlogLink slug="acquiring-bank-vs-issuing-bank">acquiring bank</BlogLink> to register
              for a virtual POS. Then you have to provide those credentials when you’re opening an
              account with a <BlogLink slug="what-is-a-payment-gateway">payment gateway.</BlogLink>
            </Trans>
            <Trans parent="p">
              But with an all-in-one payments platform like MONEI, virtual POS services are a
              built-in <InternalPageLink slug="features">feature</InternalPageLink> (at no extra
              cost).
            </Trans>
          </StyledDiv>
          <StyledDiv
            sx={{
              marginBottom: '0',
              display: {all: 'flex', sm: 'none'},
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <StyledImg src={cart2} alt="Pay less" title="Pay less" sx={{width: '55%'}} />
          </StyledDiv>
        </Section>
      </Content>

      <Content>
        <Section
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingTop: '60px',
            paddingBottom: '140px'
          }}>
          <SectionHeader underline tagName="h2">
            <Trans>Connect MONEI with your e-commerce website</Trans>
          </SectionHeader>
          <Trans parent="p">
            Seamlessly connect with all{' '}
            <InternalPageLink slug="page/payment-method-integrations-ecommerce-platforms">
              major e-commerce platforms
            </InternalPageLink>{' '}
            or integrate with your custom-built website using our{' '}
            <AnchorLink href="https://docs.monei.com/api/#tag/Payments">Payments API.</AnchorLink>
          </Trans>
          <HomePartners />
          <SignUpButton variant="light">
            <Trans>Open an Acount</Trans>
          </SignUpButton>
        </Section>
      </Content>

      <Content>
        <Section centered column style={{paddingTop: 0, paddingBottom: 0, textAlign: 'center'}}>
          <SectionHeader underline tagName="h2">
            <Trans>
              Reduce engineering costs with integration options for all your business needs
            </Trans>
          </SectionHeader>
        </Section>
        <Section sx={{alignItems: 'center', gap: '30px', paddingTop: '50px'}}>
          <StyledDiv
            sx={{
              marginBottom: '0',
              display: {all: 'flex', sm: 'none'},
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <StyledImg src={paymentPagePreview} alt="Monei pay" title="Monei pay" />
          </StyledDiv>
          <div>
            <SectionHeader>
              <Trans>Hosted Payment Page</Trans>
            </SectionHeader>
            <Trans parent="p">
              Securely collect a wide range of payment methods wih MONEI's{' '}
              <AnchorLink href="https://docs.monei.com/docs/integrations/use-prebuilt-payment-page/">
                prebuilt payment page.
              </AnchorLink>{' '}
              Add your logo, branding, and company URL to{' '}
              <SupportLink slug="/articles/360017814717">customize your checkout.</SupportLink>
            </Trans>
          </div>
        </Section>
      </Content>

      <Background sx={{paddingBottom: {sm: '50px'}}}>
        <Content>
          <Section sx={{alignItems: 'center', paddingTop: '0px', paddingBottom: '0px'}}>
            <StyledDiv>
              <SectionHeader underline sx={{paddingTop: {sm: '50px'}}}>
                <Trans>Pay By Link</Trans>
              </SectionHeader>
              <Trans parent="p">
                Send{' '}
                <InternalPageLink slug="features/pay-by-link">
                  online payment requests
                </InternalPageLink>{' '}
                via any channel with or without a website using{' '}
                <AnchorLink href="https://docs.monei.com/docs/integrations/pay-by-link/">
                  Pay By Link
                </AnchorLink>
                .
              </Trans>
            </StyledDiv>
            <StyledDiv
              sx={{
                marginBottom: '0',
                display: {all: 'flex', sm: 'none'},
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              <StyledImg src={payByLink} alt="Pay less" title="Pay less" sx={{width: '100%'}} />
            </StyledDiv>
          </Section>
        </Content>
      </Background>

      <Content>
        <Section sx={{alignItems: 'center', gap: '30px', paddingTop: {lg: '8%', sm: '20%'}}}>
          <StyledDiv
            sx={{
              marginBottom: '0',
              display: {all: 'flex', sm: 'none'},
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <StyledImg src={customCheckout} alt="Monei pay" title="Monei pay" />
          </StyledDiv>
          <div>
            <SectionHeader>
              <Trans>Custom Checkout</Trans>
            </SectionHeader>
            <Trans parent="p">
              Build a{' '}
              <AnchorLink href="https://docs.monei.com/docs/integrations/build-custom-checkout/">
                custom checkout
              </AnchorLink>{' '}
              using{' '}
              <AnchorLink href="https://docs.monei.com/docs/monei-js/overview/">
                MONEI Components
              </AnchorLink>{' '}
              to safely accept payments from your customers.
            </Trans>
          </div>
        </Section>
      </Content>

      <Content>
        <Section sx={{alignItems: 'center'}}>
          <StyledDiv>
            <SectionHeader underline>
              <Trans>Payments API</Trans>
            </SectionHeader>
            <Trans parent="p">
              Securely perform server-to-server payments with MONEI's{' '}
              <AnchorLink href="https://docs.monei.com/api/#tag/Payments">Payments API.</AnchorLink>
            </Trans>
          </StyledDiv>
          <StyledDiv
            sx={{
              marginBottom: '0',
              display: {all: 'flex', sm: 'none'},
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <CodeSnippets snippets={snippets} />
          </StyledDiv>
        </Section>
      </Content>

      <Content>
        <Section centered column sx={{textAlign: 'center', maxWidth: '1280px'}}>
          <SectionHeader underline>
            <Trans>Accept more payment methods from a single platform</Trans>
          </SectionHeader>
          <Slider
            items={paymentMethods}
            options={{
              perView: {all: 5, sm: 3, xs: 2},
              autoplay: true,
              loop: true,
              spacing: {all: 40, md: 20, sm: 10}
            }}
            sx={{overflow: 'unset'}}
            slideHeight={150}
          />
          <Button
            variant="dark"
            onClick={() => navigate('/payment-methods/')}
            style={{marginTop: 20}}>
            <Trans>View All Payment Methods</Trans>
          </Button>
        </Section>
      </Content>

      <Content>
        <Section centered column sx={{textAlign: 'center'}}>
          <SectionHeader tagName="h2" underline>
            <Trans>
              One payments platform, endless{' '}
              <InternalPageLink slug="features">features</InternalPageLink>
            </Trans>
          </SectionHeader>
        </Section>
        <CardsContainer>
          <Card>
            <CardImage src={coinstack} alt="" style={{top: -20, width: 95}} />
            <Trans parent="h4">Dynamic pricing</Trans>
            <CardWrapper>
              <Trans parent="p">
                Pay less on transaction{' '}
                <SupportLink slug="/articles/360017954318/">fees</SupportLink> as your business
                grows
              </Trans>
            </CardWrapper>
          </Card>
          <Card>
            <CardImage src={trendgraph} alt="" style={{top: -20, width: 115}} />
            <Trans parent="h4">Real-time payment analytics</Trans>
            <CardWrapper>
              <Trans parent="p">
                Take control of{' '}
                <AnchorLink href="https://dashboard.monei.com">your business</AnchorLink> with live,
                detailed online payment data at your fingertips
              </Trans>
            </CardWrapper>
          </Card>
          <Card>
            <CardImage src={shield} alt="" style={{top: -35, width: 80}} />
            <Trans parent="h4">Secure digital payments</Trans>
            <CardWrapper>
              <Trans parent="p">
                <InternalPageLink slug="pci-dss">PCI compliance</InternalPageLink>,
                <BlogLink slug="what-is-3d-secure-and-its-advantages-for-e-commerce">
                  {' '}
                  3D Secure
                </BlogLink>{' '}
                checkout,
                <AnchorLink href="https://docs.monei.com/docs/guides/save-payment-method/">
                  {' '}
                  payment tokenization
                </AnchorLink>
                , we've got you covered
              </Trans>
            </CardWrapper>
          </Card>
          <Card>
            <CardImage src={clock} alt="" style={{top: -60, width: 105}} />
            <Trans parent="h4">Fast settlements</Trans>
            <CardWrapper>
              <Trans parent="p">
                Once you’re a verified merchant, get payouts 24 hours after transactions take place
              </Trans>
            </CardWrapper>
          </Card>
          <Card>
            <CardImage src={recurring_payments} alt="" style={{top: -25, width: 105}} />
            <Trans parent="h4">Recurring payments and subscriptions</Trans>
            <CardWrapper>
              <Trans parent="p">
                Streamline
                <InternalPageLink slug="features/recurring-payments">
                  {' '}
                  recurring billing
                </InternalPageLink>{' '}
                or put it on autopilot with
                <InternalPageLink slug="features/subscriptions">
                  {' '}
                  MONEI subscriptions
                </InternalPageLink>
              </Trans>
            </CardWrapper>
          </Card>
          <Card>
            <CardImage src={person} alt="" style={{top: -45, width: 110}} />
            <Trans parent="h4">Expert support</Trans>
            <CardWrapper>
              <Trans parent="p">
                Get expert <SupportLink slug="">help</SupportLink> with onboarding, integrations,
                and choosing the right payment methods to accept
              </Trans>
            </CardWrapper>
          </Card>
        </CardsContainer>
      </Content>

      <ReviewsBackground style={{marginTop: '100px'}}>
        <Content>
          <Section centered column style={{paddingTop: 0, paddingBottom: 0, textAlign: 'center'}}>
            <SectionHeader underline tagName="h2">
              <Trans>What MONEI merchants are saying</Trans>
            </SectionHeader>
          </Section>
          <GoogleReviews place={place} />
        </Content>
      </ReviewsBackground>

      <Content>
        <GradientBox
          wrapperStyle={{overflow: 'visible', marginTop: '100px'}}
          contentStyle={{maxWidth: {all: '650px', md: '100%'}}}>
          <SectionHeader tagName="h2" underline style={{color: 'white'}}>
            <Trans>Start accepting online payments today</Trans>
          </SectionHeader>
          <GradientSectionDescription>
            <Trans parent="p">
              Accept more payment methods, increase conversions, and grow your e-commerce business
              with the all-in-one payments platform.
            </Trans>
          </GradientSectionDescription>
          <SectionActions>
            <SignUpButton variant="dark">
              <Trans>Join MONEI Now</Trans>
            </SignUpButton>
          </SectionActions>
          <GradientCtaImage
            sx={{
              bottom: '00',
              right: '70px',
              width: '30%'
            }}
            src={omnichannel}
          />
        </GradientBox>
      </Content>

      <Content>
        <Section centered column>
          <FaqsSection faqs={faqs} title={<Trans>Virtual POS FAQ</Trans>} />
        </Section>
      </Content>
    </>
  );
};

export default TpvVirtual;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "tpv-virtual"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    googlePlacesPlace {
      user_ratings_total
      rating
    }
    allGooglePlacesReview(sort: {fields: rating, order: DESC}, filter: {rating: {gt: 2}}) {
      nodes {
        id
        rating
        text
        relative_time_description
        profile_photo_url
        author_name
      }
    }
  }
`;
